export const content = [
  {
    title: 'LAUNCH',
    items: [
      '\u2713\u00a0DEX LAUNCH',
      '\u2713\u00a05000+ HOLDERS',
      '\u2713\u00a0LISTING CMC\u00a0&\u00a0CG',
      '\u2713\u00a0TREND DEXTOOLS, CMC\u00a0&\u00a0CG',
      '\u2713\u00a0TREND TWITTER, REDDIT',
    ]
  },
  {
    title: 'MARKETING',
    items: [
      '\u2713\u00a0MARKETING &\u00a0KOLs',
      '\u2713\u00a0TIER1-2 ON\u00a0BOARD',
      '\u2713\u00a0PARTNERSHIPS',
      '\u2713\u00a0COMMUNITY BUILDING',
      '\u2713\u00a0TRENDING ON\u00a0SOCIALS',
    ]
  },
  {
    title: 'LIQUIDITY',
    items: [
      '\u2713\u00a0CEX LISTINGS',
      '\u2713\u00a0STAKING &\u00a0FARMS',
      '\u2713\u00a0MULTICHAIN SUPPORT',
    ]
  },
  {
    title: 'UTILITY',
    items: [
      '\u2713\u00a0CREATE NTFs',
      '\u2713\u00a0CRAZYPEPE SWAP',
      '\u2713\u00a0STAKING &\u00a0FARMS',
      '\u2713\u00a0GLASSLESS MOONSHOTS',
      '\u2713\u00a0MULTICHAIN SUPPORT',
    ]
  }
]