import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={244}
    height={96}
    viewBox="0 0 244 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M88.974 54.474c8.599 0 15.124-4.35 16.28-10.91H99.34c-.918 3.875-4.656 5.948-9.482 5.948-5.098 0-8.02-2.685-7.171-7.477.85-4.826 4.724-7.477 9.822-7.477 4.792 0 7.783 2.04 7.375 5.948h5.914c1.155-6.56-3.807-10.91-12.406-10.91-8.598 0-15.225 4.962-16.55 12.44-1.326 7.476 3.534 12.438 12.132 12.438ZM107.692 53.93h5.845l1.7-9.856h5.777c3.977 0 4.555 1.938 7.579 9.857h7.409c-2.515-7.75-4.146-11.148-9.788-11.522h2.447c4.589 0 8.021-2.175 8.701-5.981.646-3.637-1.597-6.288-6.525-6.288h-18.931l-4.214 23.79Zm8.36-14.41.816-4.656h11.929c2.073 0 2.889.714 2.583 2.413-.272 1.495-1.325 2.243-3.398 2.243h-11.93ZM138.481 53.93h6.968l3.398-4.52h16.348l1.767 4.52h6.865l-10.162-23.79h-6.593l-18.591 23.79Zm14.003-9.482 7.069-9.414 3.704 9.414h-10.773ZM176.79 53.93h28.005l.884-4.962h-18.183l20.528-15.192.646-3.636h-27.393l-.884 4.962h17.673l-20.596 15.09-.68 3.739ZM220.237 53.93h5.845l1.394-7.748 16.109-16.042h-7.205l-10.977 11.215-7.002-11.215h-7.205l10.4 16.11-1.359 7.68Z"
      fill="#fff"
    />
    <path
      d="M85.146 90.75h18.183c4.622 0 7.239-2.516 6.593-6.322-.645-3.705-3.908-5.676-8.394-5.778 3.942-.238 6.253-1.903 5.642-5.404-.646-3.636-3.807-6.287-8.735-6.287H81l4.146 23.79Zm1.496-13.256L85.35 70.12h12.44c3.262 0 5.233 1.19 5.641 3.773.476 2.48-1.088 3.602-4.35 3.602H86.64Zm1.801 10.06L87.05 79.84h13.186c3.263 0 5.2 1.156 5.71 3.739.442 2.719-1.122 3.976-4.384 3.976H88.443ZM127.885 91.293c7.782 0 11.657-3.16 9.89-13.289l-1.972-11.045h-3.806l2.107 12.065c1.088 6.22-.816 9.142-6.797 9.142-5.948 0-8.939-2.923-10.026-9.142l-2.107-12.065h-3.807l1.971 11.045c1.768 10.128 6.764 13.289 14.547 13.289ZM146.249 90.75h3.806l-3.33-19.101 22.295 19.1h3.908l-4.146-23.79h-3.807l3.331 19.168-22.329-19.168h-3.874l4.146 23.79ZM179.107 90.75h3.806l-3.33-19.101 22.295 19.1h3.908l-4.146-23.79h-3.807l3.331 19.168-22.329-19.168h-3.875l4.147 23.79ZM220.69 90.75h3.806L223.171 83l9.176-16.041h-4.656l-7.069 12.507-11.419-12.507h-4.657l14.819 16.11 1.325 7.68Z"
      fill="#3CC1C5"
    />
    <path
      d="M56.386 73.647c0 3.27-2.693 5.922-6.014 5.922-3.322 0-6.015-2.651-6.015-5.922 0-3.27 2.693-5.921 6.015-5.921 3.322 0 6.014 2.65 6.014 5.921Z"
      fill="url(#a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.04 21.095c-.301 13.323-5.89 30.224-8.646 37.009 1.002 1.727 4.135 6.81 8.646 13.323 4.51 6.513 2.881 9.869 1.503 10.732-7.217 8.586-18.044 12.46-22.555 13.323A35.038 35.038 0 0 1 41.35 96v-6.069h-8.27v4.93c-5.433-1.648-8.806-4.722-10.15-6.78-2.796-1.836-3.651-4.631-3.361-7.754a8.675 8.675 0 0 0 7.12 3.683c4.776 0 8.647-3.811 8.647-8.512 0-4.701-3.871-8.512-8.646-8.512-.479 0-.948.038-1.406.112.995-1.445 2-2.744 2.91-3.813 0-1.48-6.767-5.551-10.15-7.402C14.134 65.653 4.386 67.356 0 66.986c3.609-1.777 8.52-18.505 10.525-26.647l.216-.265c1.3-1.603 3.305-4.076 5.417-5.651.692-.516 1.61-.577 2.296-.053 1.332 1.017 3.184 2.978 4.476 5.23 4.887 8.511 10.902 15.913 15.037 19.614-.126.617-.15 1.554.751.37.902-1.184 5.89-2.22 8.27-2.59-1.127-1.11 0-27.017 1.128-34.419C49.02 16.654 56.01 5.058 59.394 0 62.4 4.737 66.41 16.037 68.04 21.095Zm-6.39 52.552c0 6.132-5.05 11.103-11.278 11.103-6.228 0-11.277-4.971-11.277-11.103s5.049-11.102 11.277-11.102c6.228 0 11.278 4.97 11.278 11.102Z"
      fill="url(#b)"
    />
    <path
      d="M30.073 75.498c0 1.84-1.515 3.33-3.384 3.33-1.868 0-3.383-1.49-3.383-3.33s1.515-3.331 3.383-3.331c1.869 0 3.384 1.491 3.384 3.33Z"
      fill="url(#c)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={48}
        x2={71}
        y2={48}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#19FA9A" />
        <stop offset={1} stopColor="#9A44FF" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0}
        y1={48}
        x2={71}
        y2={48}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#19FA9A" />
        <stop offset={1} stopColor="#9A44FF" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={48}
        x2={71}
        y2={48}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#19FA9A" />
        <stop offset={1} stopColor="#9A44FF" />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgComponent
