export const bunnyBscList = [
  '\u2713\u00a0CEX listing',
  '\u2713\u00a0GotBit MM',
  '\u2713\u00a08500+ holders',
  '\u2713\u00a0Ads in New York Time Square',
]

export const bunnyBscPartnerList = [
  '*\u00a0BabyDoge advertised by Elon Musk',
  '*\u00a0BitcoinLFG Magazine',
  '*\u00a0OGGY',
  '*\u00a0Avadex',
  '*\u00a0Several other partnerships with a strong marketing team',
]

export const bunnySolList = [
  '\u2713\u00a0PRESALE SOLD OUT HARDCAP 2000 SOL - 15 min',
  '\u2713\u00a0X8 AFTER PRESALE PRICE',
  '\u2713\u00a0CMC&CG',
  '\u2713\u00a0LISTING MEXC',
  '\u2713\u00a0MM GOTBIT',
  '\u2713\u00a0PARTNERSHIP BITCOINLFG',
  '\u2713\u00a05k holders in 2 weeks'
]