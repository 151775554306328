import styles from './buy.module.scss';
import pepe from '../../images/pepe.png';
import movingPepe from '../../images/moving.gif';

export const Buy = () => {
  return (
    <section className={styles.wrap} id='how-to-buy'>
      <div className={styles.animatedPepe}>
        <div className={styles.animatedPepeInner}>
          <img src={movingPepe} className={styles.movingPepeImage} alt='pepe' />
        </div>
      </div>
      <h2 className={styles.title}>HOW TO BUY $CRAZYPEPE</h2>
      <div className={styles.content}>
        <div className={styles.imageBlock}>
          <img src={pepe} alt="pepe" className={styles.image} />
        </div>
        <div className={styles.stepsBlock}>
          <ul className={styles.list}>
            <li>1. Create phantom wallet by downloading the chrome extension from this <a className={styles.link} href='https://phantom.app' target='_blank'>link</a></li>
            <li>2. Buy $SOL from CEX platforms like Binance, MEXC and Coinbase</li>
            <li>3. Send $SOL to your phantom wallet</li>
            <li>4. Trade for $PEPE at Raydium by clicking on the this <a className={styles.link} href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R&fixed=in'>link</a></li>
          </ul>
        </div>
      </div>
    </section>
  )
}