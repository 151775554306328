import styles from './hero.module.scss';
import logo from '../../images/logo.png';
import codeRunning from '../../images/code-running.gif';

export const Hero = () => {
  return (
    <section className={styles.wrap}>
      <div className={styles.content}>
        <div className={styles.background}>
          <div className={styles.backgroundInner}>
            <img alt='code running' src={codeRunning} className={styles.backgroundImage} />
          </div>
        </div>
        <h1 className={styles.title}>$CrazyPEPE</h1>
        <div className={styles.mainContent}>
          <img alt='logo' src={logo} className={styles.logoImage} />
          <p className={styles.subtitle}>Pumping CrazyPEPE to $1B MCAP</p>
          <p className={styles.text}>
            WELCOME TO THE DAWN OP THE CRAZYPEPE TOKEN, AN INNOVATIVE AND EXCITING DIGITAL ASSET
          </p>
        </div>
      </div>
      <div className={styles.buttonsWrap}>
        <a href='https://www.dextools.io/app/en/solana/pair-explorer/3cBJTmgzPTQFGKxY3LzK8uYJX9WHkX15fJsxV1vDTGPJ?t=1711470874660' target='_blank' className={styles.link}>DEXTOOLS</a>
        <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R&fixed=in' target='_blank' className={styles.link}>BUY RAYDIUM</a>
      </div>
    </section>
  )
}