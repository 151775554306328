import { bunnyBscList, bunnyBscPartnerList, bunnySolList } from './team.content';
import styles from './team.module.scss';
import movingPepe from '../../images/moving.gif';

export const Team = () => {
  return (
    <section className={styles.wrap}>
      <div className={styles.animatedPepe}>
        <div className={styles.animatedPepeInner}>
          <img src={movingPepe} className={styles.movingPepeImage} alt='pepe' />
        </div>
      </div>
      <h2 className={styles.title}>TEAM CRAZYPEPE</h2>
      <div>
        <div className={styles.content}>
          <h3 className={styles.teamName}>CRAZYBUNNY BSC</h3>
          <p className={styles.text}>
            In 2023 we held a fair launch on BSC that did a 150x. 
          </p>
          <ul className={styles.list}>
            {
              bunnyBscList.map((bunnyItem) => {
                return (
                  <li key={bunnyItem} className={styles.item}>{bunnyItem}</li>
                )
              })
            }
            <li className={styles.item}>
              &#10003; Several partnerships:
              <ul className={styles.sublist}>
                {
                  bunnyBscPartnerList.map((partnerItem) => {
                    return (
                      <li className={styles.subItem} key={partnerItem}>{partnerItem}</li>
                    )
                  })
                }
              </ul>
            </li>
          </ul>
        </div>
        <div className={styles.content}>
          <h3 className={styles.teamName}>CRAZYBUNNY SOL</h3>
          <ul className={styles.list}>
            {
              bunnySolList.map((item) => {
                return (
                  <li className={styles.item} key={item}>{item}</li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </section>
  )
}