import GotbitIcon from '../../icons/GotbitIcon';
import CoinMarketIcon from '../../icons/CoinMarketIcon';
import CoinGeckoIcon from '../../icons/CoinGeckoIcon';
import RadiumIcon from '../../icons/RadiumIcon2';
import BunnyIcon from '../../icons/BunnyIcon';
import solpad from '../../images/partners/solpad.png';
import btclfg from '../../images/partners/btclfg.png';
import babyDoge from '../../images/partners/baby-doge.png';
import dextools from '../../images/partners/dextools.png';

export const partnersList = [
  {
    name: 'Gotbit',
    link: 'https://gotbit.io/',
    icon: GotbitIcon,
  },
  {
    name: 'SolPad',
    link: 'https://launchpad.solpad.io/',
    image: solpad,
  },
  {
    name: 'CMC',
    link: 'https://coinmarketcap.com/',
    icon: CoinMarketIcon,
  },
  {
    name: 'BitcoinLFG',
    link: 'https://bitcoinlfg.com/',
    image: btclfg,
  },
  {
    name: 'CG',
    link: 'https://www.coingecko.com/',
    icon: CoinGeckoIcon,
  },
  {
    name: 'Raydium',
    link: 'https://raydium.io/',
    icon: RadiumIcon,
  },
  {
    name: 'BabyDoge',
    link: 'https://babydoge.com/',
    image: babyDoge,
  },
  {
    name: 'CrazyBunny',
    link: 'http://crazybunny.top',
    icon: BunnyIcon,
  },
  {
    name: 'Dextools',
    link: 'https://www.dextools.io/',
    image: dextools,
  }
]